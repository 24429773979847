<template>
	<v-ons-page :class="['side-menu', { 'is-open': menuOpen }]">
		<v-ons-list>
			<div class="main-list">
				<v-ons-list-item class="main-list__item">
					<div @click="routerLink('painNote')">
						<i :class="[
							'zutool-icon',
							selectedPage === 'PainNote' ? 'btn_menutop1_on' : 'btn_menutop1'
						]" />
					</div>
				</v-ons-list-item>
				<v-ons-list-item class="main-list__item">
					<div @click="routerLink('weatherGraph')">
						<i :class="[
							'zutool-icon',
							selectedPage === 'WeatherGraph' ? 'btn_menutop2_on' : 'btn_menutop2'
						]" />
					</div>
					<div @click="routerLink('weatherMap')">
						<i :class="[
							'zutool-icon',
							selectedPage === 'WeatherMap' ? 'btn_menutop3_on' : 'btn_menutop3'
						]" />
					</div>
				</v-ons-list-item>
			</div>
			<!-- 広告（top） -->
			<div class="top-item-banner" v-if="computedMenuTopItemList.length !== 0">
				<div class="top-item-banner__area" v-for="(item, index) in computedMenuTopItemList" :key="`top-${index}`">
					<img class="top-item-banner__area--image" :src="item.image" @click="routerLink(item.link_url)">
					<img class="top-item-banner__area--new" src="../../assets/img/badge_n.png" v-if="item.new_flag === '1'">
				</div>
			</div>
			<!-- /広告 -->
			<div class="sub-list" :style="computedMenuTopItemList.length !== 0 ? 'margin-top: 0;' : 'margin-top: $spacing-16;'">
				<div class="sub-list__inner">
					<v-ons-list-header>
						<div class="center">情報</div>
					</v-ons-list-header>
					<v-ons-list-item tappable class="sub-list__item" @click="routerLink('columnTop')">
						<div class="center">頭痛ーるコラム</div>
					</v-ons-list-item>
				</div>
				<div class="sub-list__inner">
					<v-ons-list-header>
						<div class="center">設定</div>
					</v-ons-list-header>
					<v-ons-list-item tappable class="sub-list__item is-icon" @click="routerLink('pointRegistration')">
						<div class="left">
							<i class="zutool-icon menu_icn_chiten_touroku" />
						</div>
						<div class="center">地点登録</div>
					</v-ons-list-item>
					<v-ons-list-item tappable class="sub-list__item is-icon" @click="routerLink('profile')">
						<div class="left">
							<i class="zutool-icon menu_icn_profile" />
						</div>
						<div class="center">プロフィール</div>
					</v-ons-list-item>
				</div>
				<div class="sub-list__inner">
					<v-ons-list-header>
						<div class="center">その他</div>
					</v-ons-list-header>
					<v-ons-list-item tappable class="sub-list__item" @click="routerLink('frequentlyAskedQuestions')">
						<div class="center">よくある質問</div>
					</v-ons-list-item>
					<v-ons-list-item tappable class="sub-list__item" @click="routerLink('charactor')">
						<div class="center">キャラクター紹介</div>
					</v-ons-list-item>
					<v-ons-list-item tappable class="sub-list__item" @click="routerLink('dataHandling')">
						<div class="center">データの取り扱いについて</div>
					</v-ons-list-item>
					<v-ons-list-item tappable class="sub-list__item" @click="routerLink('terms')">
						<div class="center">利用規約</div>
					</v-ons-list-item>
				</div>
			</div>
			<!-- 広告（bottom） -->
			<div class="bottom-item-banner" v-if="computedMenuBottomItemList.length !== 0">
				<div class="bottom-item-banner__area" v-for="(item, index) in computedMenuBottomItemList" :key="`bottom-${index}`">
					<img class="bottom-item-banner__area--image" :src="item.image" @click="routerLink(item.link_url)">
					<img class="bottom-item-banner__area--new" src="../../assets/img/badge_n.png" v-if="item.new_flag === '1'">
				</div>
			</div>
			<!-- /広告 -->
		</v-ons-list>
		<!-- 気圧グラフの鉛筆マーク・プロフィールをクリックした際のポップアップ -->
		<!-- NOTE: visiblePopUpTypeがprofileの場合、プロフィール用のモーダル。それ以外の文字列はデフォルトのモーダル。空の場合は表示しない。 -->
		<v-ons-modal :visible="visiblePopUpType !== ''" animation="fade" animationOptions="{duration: 0.2, timing: 'ease-in'}" class="side-menu-modal" @preshow="modalImage = visiblePopUpType" @posthide="modalImage = ''">
			<aside class="side-menu-modal__inner">
				<div class="side-menu-modal__body">
					<i :class="['sprite_sidemenu_modal', modalImage]" />
				</div>
				<div class="side-menu-modal__content">
					<p class="side-menu-modal__content--text">アプリは便利な機能が使い放題！体調を崩しやすい日もプッシュ通知でもお知らせします！</p>
					<a href="javascript:void(0);" @click="clickModalLink($event)" :id="'trackingSideMenuModalBtn_' + visiblePopUpType" class="side-menu-modal__content--button">
						<i class="sprite_sidemenu_modal button" title="アプリダウンロード" />
					</a>
				</div>
				<div class="side-menu-modal__button">
					<a class="side-menu-modal__button--link" @click="SET_VISIBLE_POPUP_TYPE('')">
						<ons-icon icon="md-close" />表示を閉じる
					</a>
				</div>
			</aside>
		</v-ons-modal>
		<!-- /気圧グラフの鉛筆マーク・プロフィールをクリックした際のポップアップ -->
	</v-ons-page>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_MENU_OPEN, SET_MENU_ITEM, SET_VISIBLE_POPUP_TYPE } from '../../store/modules/common/mutation-types'
import cmnConst from '@/assets/js/constant.js'
import moment from 'moment'
moment.locale('ja')

export default {
	name: 'SideMenuSugotoku',
	data () {
		return {
			menuTopItemList: [],
			menuBottomItemList: [],
			modalImage: ''
		}
	},
	props: {
		selectedPage: {
			type: String,
			default: 'WeatherGraph'
		}
	},
	mounted () {
		// NOTE: メニュー取得処理
		this.SET_MENU_ITEM({
			user_id: this.userId,
			user_token: this.userToken
		}).then(res => {
			const menuItemList = this.menuItem.records
			// NOTE メニューのトップとボトムにデータを分ける
			// NOTE: APIはperiod_startが７日前のものとperiod_endがまだ終了していないものを返す
			// NOTE: WEB版のサイドメニューはandroidには表示されないのでtarget_osがiosのバナーのみ表示
			// CHANGE: menuItemListがある場合のみ処理するよう対応
			if (menuItemList) {
				const now = moment().unix()
				this.menuTopItemList = menuItemList.filter(item => item.banner_type === 'sugotoku' && item.position === 'top' && item.target_user.includes('4') && now > item.period_start.sec && item.target_os.includes('ios'))
				this.menuBottomItemList = menuItemList.filter(item => item.banner_type === 'sugotoku' && item.position === 'bottom' && item.target_user.includes('4') && now > item.period_start.sec && item.target_os.includes('ios'))
				// NOTE 表示順をorder順(昇順)に並び替える
				this.menuTopItemList.sort((a, b) => {
					if (a.order < b.order) return -1
					if (a.order > b.order) return 1
					return 0
				})
				this.menuBottomItemList.sort((a, b) => {
					if (a.order < b.order) return -1
					if (a.order > b.order) return 1
					return 0
				})
			}
		}).catch(error => {
			console.log(error)
		})
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['userId', 'userToken', 'menuOpen', 'menuItem', 'visiblePopUpType']),

		computedMenuTopItemList () {
			return this.menuTopItemList
		},

		computedMenuBottomItemList () {
			return this.menuBottomItemList
		}
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_MENU_OPEN, SET_MENU_ITEM, SET_VISIBLE_POPUP_TYPE]),

		/**
		 * ルーティング用リンク
		 */
		routerLink (path) {
			// CHANGE: スゴ得はプロフィール登録と痛み記録廃止
			if (path === 'profile') {
				this.SET_VISIBLE_POPUP_TYPE(path)
			} else if (path === 'painNote') {
				this.SET_VISIBLE_POPUP_TYPE('painrecord')
			} else {
				this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=${path}` } }).catch(error => {
					if (error.name !== 'NavigationDuplicated') {
						throw error
					}
				})
			}
			this.SET_MENU_OPEN(false)
		},

		/**
		 * モーダルのアプリダウンロードボタンクリックイベント
		 */
		clickModalLink (event) {
			event.preventDefault()
			this.SET_VISIBLE_POPUP_TYPE('')
			this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=appDownload`, _backPage: 'top' } }).catch(error => {
				if (error.name !== 'NavigationDuplicated') throw error
			})
		}
	},
	beforeDestroy () {
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

$side_menu_width: 240px;

ons-list {
	overflow: visible;
}

.side-menu {
	overflow-y: visible;
	&.is-open:before {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		left: $side_menu_width;
		width: 10px;
		height: 100%;
		background: linear-gradient(90deg, rgba($_black, .4) 0%, rgba($_black, .0) 100%) no-repeat;
	}
}

ons-list-header {
	padding-left: $spacing-10;
	height: 25px;
	color: $text-primary;
	font-size: $font-size-10;
	font-family: $family-primary;
	background-color: $background-list;
}

.main-list {
	margin-bottom: 4px;
	box-shadow: 0px 4px 8px 0px rgba($_black, .2);
	&__item {
		width: $side_menu_width;
		padding: 0;
	}
	/deep/ .list-item__center {
		padding: 0;
	}
}

.sub-list {
	margin-top: $spacing-16;
	box-shadow: 0px -4px 8px 0px rgba($_black, .2), 0px 4px 8px 0px rgba($_black, .2);
	&__item {
		width: $side_menu_width;
		height: 50px;
		padding: 0 $spacing-10;
		color: $link;
		font-size: $font-size-12;
		font-family: $family-primary;
		.center {
			padding: 0 $spacing-10;
		}
	}
	&__item > div {
		border-bottom: 1px solid $line-primary;
	}
	&__inner .sub-list__item:last-of-type > div {
		border-bottom: none;
	}
}
.center {
	background-image: none;
}
.left {
	padding: 0 0 0 $spacing-10;
}

.top-item-banner {
	&__area {
		position: relative;
		&--image {
			width: 100%;
			margin: $spacing-2 0 $spacing-2 0;
		}
		&--new {
			position: absolute;
			width: 20px;
			top: 4px;
			right: 4px;
		}
	}
}

.bottom-item-banner {
	margin-top: $spacing-10;
	&__area {
		position: relative;
		&--image {
			width: 100%;
			margin: $spacing-2 0 $spacing-2 0;
		}
		&--new {
			position: absolute;
			width: 20px;
			top: 4px;
			right: 4px;
		}
	}
}

// NOTE: side-menu-modal限定の変数（デザインシステム外のため他での利用禁止）
$spacing-12: 0.75rem;
$spacing-27: 1.687rem;

.side-menu-modal {
	position: fixed;

	/deep/ .modal__content {
		padding-top: 75px;
		vertical-align: top;
	}

	&__inner {
		width: 300px;
		margin: 0 auto;
		background-color: $background-primary;
	}

	&__body {
		position: relative;
		padding: $spacing-27 0 $spacing-20 0;

		.sprite_sidemenu_modal { margin: 0 auto; }
	}

	&__content {
		padding-bottom: $spacing-12;

		&--text {
			margin: 0;
			padding: 0 $spacing-16;
			font-size: $font-size-12;
			color: $text-primary;
			text-align: left;
		}

		&--button {
			display: block;
			margin-top: $spacing-12;

			.sprite_sidemenu_modal { margin: 0 auto; }
		}
	}

	&__button {
		display: table;
		width: 300px;
		height: 43px;
		background: $background-primary;
		box-sizing: border-box;

		&--link {
			display: table-cell;
			width: 100%;
			vertical-align: middle;
			text-align: center;
			color: $text-primary;
			font-size: $font-size-12;
		}

		&--link ons-icon {
			font-size: $font-size-16;
			vertical-align: middle;
			margin-right: $spacing-8;
		}
	}

	// スプライト画像
	.sprite_sidemenu_modal {
		background-image: url(~@/assets/img/sprite_sidemenu_modal.png);
		background-size: (570px / 2) (758px / 2);
		background-repeat: no-repeat;
		display: block;

		&.painrecord {
			width: (520px / 2);
			height: (321px / 2);
			background-position: (-5px / 2) (-5px / 2);
		}

		&.profile {
			width: (440px / 2);
			height: (321px / 2);
			background-position: (-5px / 2) (-336px / 2);
		}

		&.button {
			width: (560px / 2);
			height: (86px / 2);
			background-position: (-5px / 2) (-667px / 2);
			background-color: transparent;
		}
	}
}

// トランジション
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
</style>
